










































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    participant: {
      type: Object,
      required: true,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const model = reactive({
      paymentDate: "",
      receiptNumber: "",
      receiptDate: "",
      comments: "",
      notes: "",
      invoiceType: 1,
      dateOfInvoice: "",
      payu: false,
      email: "",
      invoiceName: "",
      existDateOfInvoice: "",
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      empty: false,
      table: false,
      loaded: false,
      errorDialog: false,
      disableButton: false,
      newInvoice: false,
      existInvoice: false,
      formDisabled: false,
      item: {},
    });

    const nowDate = () => {
      model.dateOfInvoice = new Date().toISOString().slice(0, 10);
    };

    onMounted(nowDate);

    const closeDialog = () => {
      emit("close-dialog");
    };

    const closeSuccess = () => {
      state.errorDialog = false;
      emit("close-dialog");
      emit("fetch-data");
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${props.participant.id}`)
        .then(({ data: { participant } }) => {
          state.item = participant;
          model.paymentDate = participant.participantRegistrationFeeTerm
            .paymentDate
            ? participant.participantRegistrationFeeTerm.paymentDate
            : "";
          model.email = participant.invoiceEmail
            ? participant.invoiceEmail
            : "";
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.participantNotFound")}`;
        case 406:
          return `${root.$tc("layout.errors.noFeeAssigned")}`;
        case 414:
          return `${root.$tc(
            "panel.event.participant.invoice.someFieldsAreEmpty"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const createNewInvoice = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        paymentDate: model.paymentDate || undefined,
        receiptNumber: model.receiptNumber || undefined,
        receiptDate: model.receiptDate || undefined,
        comments: model.comments || undefined,
        notes: model.notes || undefined,
        invoiceType: model.invoiceType,
        payU: model.payu || undefined,
        email: model.email || undefined,
      };

      state.loading = true;

      axiosInstance
        .post(`invoice/participant/${props.participant.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          state.disableButton = true;
          state.formDisabled = true;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.participant.invoice.success"),
          });

          setTimeout(() => {
            emit("close-dialog");
            emit("fetch-data");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => {
          state.loading = false;
          state.formDisabled = false;
        });
    };

    const addInvoice = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        participantId: props.participant.id,
        invoiceName: model.invoiceName,
        dateOfInvoice: model.existDateOfInvoice,
      };

      state.loading = true;

      axiosInstance
        .post("/invoice/assign", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          state.disableButton = true;
          state.formDisabled = true;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.participant.invoice.existSuccess"),
          });

          setTimeout(() => {
            emit("close-dialog");
            emit("fetch-data");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => {
          state.loading = false;
          state.formDisabled = false;
        });
    };

    return {
      model,
      state,
      closeDialog,
      createNewInvoice,
      closeSuccess,
      getErrorMessage,
      addInvoice,
    };
  },
});
